import { type LocationDescriptor } from '@scentregroup/shared/next/compat/history'
import { pathRemovingBasePath } from '../../lib/path'

export function locationDescriptorRemovingBasePath<S>(
  location: LocationDescriptor<S>,
  basePath: string
): LocationDescriptor<S> {
  if (typeof location === 'string') {
    return pathRemovingBasePath(location, basePath)
  }
  const { pathname: rawPathname } = location
  const pathname = rawPathname && pathRemovingBasePath(rawPathname, basePath)
  return { ...location, pathname }
}
