import React from 'react'
import Layout from '@scentregroup/shared/hub-components/layout'
import { useCountry } from '@scentregroup/shared/country'
import ErrorFeature from '../../components/error-feature'
import { CentreStaticData } from '../../helpers/fetch-header-static-data'

type ErrorProps = {
  error?: unknown
  status?: number
  headerStaticData: Record<string, unknown>
}

const Error: React.FC<ErrorProps> = ({
  error,
  status = 404,
  headerStaticData,
}) => {
  const country = useCountry()
  return (
    <Layout
      country={country}
      header={{
        variant: 'national',
        data: headerStaticData as CentreStaticData,
      }}
    >
      <ErrorFeature error={error} status={status} />
    </Layout>
  )
}

export default Error
