const YOUTUBE_REGEX =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/ // eslint-disable-line no-useless-escape
const VALID_RESULT_LENGTH = 7
const YOUTUBE_ID_INDEX = 5
const QUERYSTRING_INDEX = 6
const YOUTUBE_ID_LENGTH = 11
export const getYoutubeUrlDetails = url => {
  const details = YOUTUBE_REGEX.exec(url)
  if (!details) {
    return null
  }
  if (details.length !== VALID_RESULT_LENGTH) {
    // regex should return exactly 7 groups
    return null
  }

  if (details[YOUTUBE_ID_INDEX].length !== YOUTUBE_ID_LENGTH) {
    // the 6th group should be the youtube id and should be 11 chars
    return null
  }
  return {
    youtubeId: details[YOUTUBE_ID_INDEX],
    queryString: details[QUERYSTRING_INDEX],
  }
}
