import React from 'react'
import PropTypes from 'prop-types'
import style from './index.module.scss'

const YoutubeLink = ({ title, youtubeId }) => (
  <span className={style.videoContainer}>
    <iframe
      allowFullScreen
      title={title || `YouTube video ${youtubeId}`}
      src={`https://www.youtube.com/embed/${youtubeId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    />
  </span>
)

YoutubeLink.propTypes = {
  youtubeId: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default YoutubeLink
