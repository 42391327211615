/* eslint-disable react/prefer-stateless-function */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from '@scentregroup/shared/next/compat/react-router'
import classNames from 'classnames'
import ErrorFeature from '../components/error-feature'
import Spinner from '../components/spinner'
import { getDisplayName } from '../helpers/get-display-name'
import canonicalLink from '../helpers/canonical-link'
import { useGetHrefContext } from '../context'
import DataLayer from '../components/data-layer'
import ErrorPage from './error'

const INTERNAL_SERVER_ERROR = 500

const pagesHoc = (
  WrappedComponent,
  getMetaData,
  canonicalizeUrl = canonicalLink.canonical
) => {
  const NewComponent = props => {
    const getUrl = useGetHrefContext()
    const { centre, error, loading, redirect, brand } = props

    if (error) {
      return centre ? (
        <ErrorFeature
          status={error.code || INTERNAL_SERVER_ERROR}
          error={error}
        />
      ) : (
        <ErrorPage status={error.code} error={error} />
      )
    }

    if (loading) {
      // Loading page
      return <Spinner className={classNames({ brand })} />
    }

    if (redirect) {
      return <Redirect to={redirect} />
    }

    const canonical = canonicalizeUrl(getUrl(), props)

    const meta =
      getMetaData?.({
        ...props,
        canonical,
      }) ?? {}

    return (
      <Fragment>
        <DataLayer {...meta.datalayer} />
        <WrappedComponent {...props} />
      </Fragment>
    )
  }
  NewComponent.displayName = `Page(${getDisplayName(WrappedComponent)})`
  NewComponent.propTypes = {
    error: PropTypes.object,
    loading: PropTypes.bool,
    redirect: PropTypes.string,
    centre: PropTypes.object,
    brand: PropTypes.bool,
  }
  return NewComponent
}

export default pagesHoc
