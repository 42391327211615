import { HubStyleObject } from '@hub/design-system-base'

export const markdownStyle: HubStyleObject = {
  '& > * + *': {
    marginTop: 'spacing-md',
  },
  '& :is(h1, h2, h3, h4, h5, h6) + :is(p, ol, ul, table)': {
    marginTop: 'spacing-sm',
  },
  '& em': {
    fontStyle: 'italic',
  },
  '& ul, ol': {
    listStylePosition: 'inside',
  },
  '& li': {
    marginBottom: 'spacing-md',
    '& p': {
      display: 'inline',
    },
  },
  [`& li ul,
      li ol`]: {
    marginTop: 'spacing-md',
  },
  '& ul ul, ol ul': {
    listStyleType: 'circle',
  },
  [`& ol ol ul,
      ol ul ul,
      ul ol ul,
      ul ul ul
  `]: {
    listStyleType: 'square',
  },
  '& strong': {
    fontWeight: 'medium',
  },
  '& pre': {
    fontFamily: 'monospace',
  },
  '& tbody tr': {
    borderTop: '1px solid',
    borderTopColor: 'ghost',
  },
  '& table': {
    width: '100%',
    border: '1px solid',
    borderColor: 'licorice',
    borderCollapse: 'collapse',
    marginTop: 'spacing-sm',
    marginBottom: 'spacing-xl',
    ['@media all and (max-width: 447px)']: {
      fontSize: 'font-sm',
    },
  },
  '& table h4': {
    fontWeight: 'normal',
    ['@media all and (max-width: 447px)']: {
      fontSize: 'font-sm',
    },
  },
  '& th, td': {
    border: '1px solid',
    borderColor: 'ghost',
    textAlign: 'center',
    paddingY: 'spacing-xs',
    paddingX: 0,
  },
  '& th': {
    backgroundColor: 'smoke',
  },
  '& a': {
    textDecoration: 'underline',
    textDecorationColor: 'licorice',
    '&:hover': {
      textDecorationColor: 'licorice',
    },
    '&:active': {
      color: 'licorice',
      textDecorationColor: 'licorice',
    },
    '&:visited': {
      textDecorationColor: 'licorice',
    },
  },
}
