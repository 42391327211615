import React, { createContext, useContext } from 'react'

const HocContext = createContext({})

// key is any valid folder name in `src/shared/containers`
export const useGraphQLData = (key: string): { [k: string]: any } =>
  useContext<{ [k: string]: any }>(HocContext)[key]

export const GraphQLHocProvider: React.FC<
  React.PropsWithChildren<{ value: any }>
> = ({ children, value }) => (
  <HocContext.Provider value={value}>{children}</HocContext.Provider>
)
