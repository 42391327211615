import type { ApolloError } from '@apollo/client'
import { Country } from './country'
import { CFPromotion, CFContentPageCollection } from './contentful'

export interface Variables {
  path: string
  country: Country[]
}

export interface Data {
  cfContentPageCollection?: CFContentPageCollection
  loading?: boolean
  error?: ApolloError
}

export interface Response {
  data?: Data
}

export interface MetaData {
  shortDescription: string
  title: string
  path: string
  country: Country[]
  metaTitle?: string
}

export interface NavigationItem {
  url: string
  label: string
}

export interface NavigationGroup {
  title: string
  items: NavigationItem[]
}

export interface Props {
  error?: Error
  loading?: boolean
  modules?: (Promotion | PromotionFeature | ComparisonModule | TermsModule)[]
  metaData?: MetaData
  navigationMenu?: (NavigationItem | NavigationGroup)[]
}

export enum ContentPageDisplayModes {
  COMPARISON_ITEM = 'Comparison Item',
  PROMOTION = 'Promotion',
  FEATURE = 'Feature',
  BASIC_CONTENT = 'basicContent',
}

export function isPromotionFeature(arg: unknown): arg is PromotionFeature {
  return (
    (arg as PromotionFeature).displayMode === ContentPageDisplayModes.FEATURE
  )
}

export function isTermsModule(arg: unknown): arg is TermsModule {
  return (arg as TermsModule).slug !== undefined
}

export function isDisplayMode(
  mode: ContentPageDisplayModes,
  toCompare: ContentPageDisplayModes
): boolean {
  return mode === (toCompare as typeof toCompare)
}

export interface PromotionFeature {
  sys: { contentTypeId: string }
  displayMode: ContentPageDisplayModes
  title: string
  body: string
  shortDescription: string
  desktopImageUrl: string
  mobileImageUrl: string
  callToAction: { address: string; category: string }
}

export interface Promotion
  extends Pick<CFPromotion, Exclude<keyof CFPromotion, '__typename' | 'sys'>> {
  sys: { contentTypeId: string }
  displayMode: ContentPageDisplayModes
  image: { url: string; title: string; description: string }
  title: string
  heading: string
  shortDescription: string
  callToActionLabel: string
  callToActionAddress: string
  eventCategory?: string
  eventAction?: string
}

interface ComparisonItem {
  productName: string
  dotPoints: string[]
  callToAction: { url: string; category: string }
}

export interface ComparisonModule {
  type: string
  displayMode: ContentPageDisplayModes.COMPARISON_ITEM
  entries: ComparisonItem[]
}

export interface TermsModule {
  bodyCopy: string
  title: string
  slug: string
}

type Modules = (Promotion | PromotionFeature | ComparisonModule | TermsModule)[]

export type ContentPageData = {
  modules: Modules
  metaData: MetaData
  jsonData: unknown | null
}
