import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Container, Heading } from '@scentregroup/ui'
import ResponsiveImage from '../responsive-image'
import Image from '../image'
import { CustomThemeContext } from '../../context'
import Trackable from '../trackable'
import { trackingActions, trackingTypes } from '../../constants'
import style from './index.module.scss'

const PageLanding = ({
  desktopImage,
  mobileImage,
  heading,
  logo,
  children,
  dataTestId,
  imageDataTestId,
  analyticsMetadata,
}) => (
  <CustomThemeContext.Consumer>
    {themeOption => (
      <Trackable
        trackingAction={trackingActions.viewPromotion}
        type={trackingTypes.promotion}
        {...analyticsMetadata}
      >
        <div className={style.evolved}>
          {heading && (
            <Container type="large" className={style.heading}>
              <Heading type="medium" data-test-id={dataTestId}>
                {heading}
              </Heading>
            </Container>
          )}
          <div
            className={classNames(style.pagelanding, {
              [style.withLogo]: Boolean(logo),
              [style.withHeading]: Boolean(heading),
              [style.Grey]: themeOption.luxury,
            })}
          >
            <div className={style.featureWrapper}>
              {Boolean(desktopImage.src || mobileImage.src) && (
                <ResponsiveImage
                  lazyLoad={false}
                  className={style.feature}
                  angleOrigin={null}
                  desktopImageUrl={desktopImage.src}
                  mobileImageUrl={mobileImage.src}
                  mobileRatio="standard"
                  desktopRatio="feature"
                  numberOfItems={1}
                  dataTestId={imageDataTestId}
                  alt={desktopImage.alt || mobileImage.alt || ''}
                />
              )}
              {logo && (
                <Image
                  className={style.logo}
                  imageUrl={logo}
                  ratio="square"
                  bgSize="contain"
                  alt={heading}
                />
              )}
              <Container centred type="large" className={style.container}>
                {children}
              </Container>
            </div>
          </div>
        </div>
      </Trackable>
    )}
  </CustomThemeContext.Consumer>
)

PageLanding.propTypes = {
  desktopImage: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
  }),
  mobileImage: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
  heading: PropTypes.string,
  logo: PropTypes.string,
  dataTestId: PropTypes.string,
  imageDataTestId: PropTypes.string,
  analyticsMetadata: PropTypes.shape({
    correlationId: PropTypes.string,
    title: PropTypes.string,
    promotion: PropTypes.shape({
      creative: PropTypes.string,
    }),
  }),
  angleClasses: PropTypes.object,
}
PageLanding.defaultProps = {
  desktopImage: {
    alt: null,
    src: null,
  },
  mobileImage: {
    alt: null,
    src: null,
  },
}

export default PageLanding
