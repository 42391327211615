//
// This file is auto generated. See ./README.md for details
//
import React from 'react'
import { PropsType } from '../../types/common'
import { useGraphQLData } from '../graphql-hoc-provider'

// replacement for Apollo's graphql() HOC
const ApolloGraphQLOverride = (Component: any) => {
  const NewComponent = (props: PropsType) => {
    const data = useGraphQLData('with-content-page')
    return <Component {...props} {...data} />
  }
  NewComponent.displayName = `withContentPageOverride(${
    Component.displayName || Component.name || 'unknown'
  })`
  return NewComponent
}
export default ApolloGraphQLOverride
