// Loading Spinner
// ===============
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import style from './index.module.scss'

const Spinner = props => (
  <div
    className={classNames(style.Spinner, {
      [style[props.className]]: props.className,
    })}
  />
)

Spinner.propTypes = {
  className: PropTypes.string,
}

export default Spinner
