import hero from './hero.jpg'

const CodesToErrDetails = {
  404: {
    body: `Looks like there is nothing here.

  [Back to the homepage](/)`,
    imageUrl: hero,
    title: 'Uh Oh!',
    linkText: 'Back to the homepage',
    link: '/',
  },

  500: {
    body: `Sorry, we're having trouble fetching that request.
  Please try again later.

  [Back to the homepage](/)`,
    imageUrl: hero,
    title: 'Uh Oh!',
    linkText: 'Back to the homepage',
    link: '/',
  },
  default: {
    body: `Sorry, we're having trouble fetching that request.
  Please try again later.

  [Back to the homepage](/)`,
    imageUrl: hero,
    title: 'Uh Oh!',
    linkText: 'Back to the homepage',
    link: '/',
  },
}

export default CodesToErrDetails
