import { PureComponent } from 'react'
import analyticsUtils from '../../helpers/analytics-utils'

class DataLayer extends PureComponent {
  componentDidMount() {
    this.pushDataLayer(this.props)
  }

  componentDidUpdate() {
    this.pushDataLayer(this.props)
  }

  pushDataLayer(dataLayerObject) {
    if (dataLayerObject && Object.keys(dataLayerObject).length > 0) {
      const datalayerGoogle = {}

      // google wants syntax { 'variable': 'value'}
      Object.keys(dataLayerObject).map(
        key => (datalayerGoogle[`'${key}'`] = dataLayerObject[key])
      )
      analyticsUtils.DEPRECATED_gaTagTracking(dataLayerObject)
    }
  }

  render() {
    return []
  }
}

export default DataLayer
