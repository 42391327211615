/* eslint-disable no-negated-condition */

import { getClientConfigValue } from '../../config'

export const getCountry = staticContext => {
  if (staticContext && staticContext.country) {
    return staticContext.country
  } else if (typeof window !== 'undefined') {
    return getClientConfigValue('COUNTRY')
  } else {
    return 'Australia'
  }
}
