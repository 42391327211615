import React from 'react'
import { InferGetServerSidePropsType } from 'next'
import { localeToCountry } from '@scentregroup/shared/helpers/local-to-country'
import { getDefaultNationalMetaData } from '@scentregroup/shared/helpers/get-default-national-metadata'
import { setCacheHeaders } from '@scentregroup/shared/helpers/set-cache-headers'
import { useGetHrefContext } from '@scentregroup/shared/context'
import Layout from '@scentregroup/shared/hub-components/layout'
import GiftCards from '@scentregroup/shared/pages/promotional-page'
import { getContentPage } from '../lib/get-content-detail/content-page'
import { GetServerSideProps, GetServerSidePropsContext } from '../types/next'
import { makeApolloClient } from '../lib/apolloClient'
import { propsOrNotFound } from '../lib/props-or-not-found'
import { completelyCanonicalUrl } from '@scentregroup/shared/helpers/canonical-link'
import { fetchGiftcardsStaticData } from '@scentregroup/shared/helpers/fetch-header-static-data'

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  res,
}: GetServerSidePropsContext) => {
  setCacheHeaders(res)
  const client = makeApolloClient()
  const country = localeToCountry(locale)

  const [giftCardHeaderData, contentData] = await Promise.all([
    fetchGiftcardsStaticData(client, { country }),
    getContentPage(client, country, 'gift-cards'),
  ])

  return propsOrNotFound({
    props: {
      country,
      giftCardHeaderData,
      contentData,
    },
  })
}

const GiftCardsPage: React.FC<
  React.PropsWithChildren<
    InferGetServerSidePropsType<typeof getServerSideProps>
  >
> = props => {
  const { country, giftCardHeaderData, contentData } = props
  const {
    modules,
    metaData: { metaTitle: title, shortDescription },
  } = contentData
  const canonical = completelyCanonicalUrl(useGetHrefContext()())
  const defaultMetaData = getDefaultNationalMetaData({
    country: props.country,
    type: 'gift-cards',
  })

  const metaData = {
    title: title || defaultMetaData.title,
    description: shortDescription || defaultMetaData.description,
    canonical,
  }

  return (
    <Layout
      country={country}
      topStrip={null}
      centre={undefined}
      header={{
        variant: 'gift-cards',
        data: giftCardHeaderData,
      }}
      metaData={metaData}
      showCVPStrip={false}
      showRobotsMetaTag={false}
    >
      <GiftCards modules={modules} />
    </Layout>
  )
}

export default GiftCardsPage
