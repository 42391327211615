import { MetaData } from '@scentregroup/shared/types/content-page'

export const titleFromContentPage = (
  country: string,
  metaData?: MetaData
): string => {
  if (metaData) {
    return metaData.metaTitle
      ? metaData.metaTitle
      : `Westfield ${metaData.title}`
  } else {
    return `Westfield ${country}`
  }
}
